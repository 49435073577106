<template>
  <b-container class="mt-3">
    <b-card>
      <h3 class="mb-2">
        <strong>Types of Accounts</strong>
      </h3>
      <div class="mb-1">
        <strong>A. Individual Business</strong>
      </div>
      <div><strong>- Description:</strong></div>
      <p>
        By signing up for MuslimDo as an individual business, you can enjoy a
        range of benefits that will enhance your event management capabilities.
        Even without a physical headquarters, you can take control of your events
        like a pro. MuslimDo provides you with the tools and platform to increase
        your chances of success as a freelancer or a productive family member.
        Additionally, you will have the opportunity to connect with a diverse
        community of like-minded individuals, fostering collaboration and
        networking opportunities. By leveraging MuslimDo's resources, you can make
        a name for yourself in the event industry and maximize the success of your
        events.
      </p>
      <div><strong>Key Benefits:</strong></div>
      <p>
        1. Seamless Booking Process: MuslimDo streamlines the event booking
        process, making it easier for individual businesses to manage event
        registrations and ticket sales. With a user-friendly interface, you can
        efficiently handle booking requests and provide a smooth experience for
        your attendees.
      </p>
      <p>
        2. Events Analytics: Gain valuable insights into your
        events through MuslimDo's comprehensive analytics. Access data on
        attendance, ticket sales, demographics, and more. These analytics empower
        you to make data-driven decisions, optimize your event strategies, and
        improve future event planning.
      </p>
      <p>
        3. Fundraising Projects: Also, you can
        create fundraising projects related to your events. This feature allows
        you to raise funds for charitable causes, community initiatives, or
        event-related expenses. Through MuslimDo, you can engage your audience and
        create a positive impact while organizing successful events.
      </p>
      <div class="mb-1">
        <strong>B. Organization Owner</strong>
      </div>
      <div><strong>- Description:</strong></div>
      <p>
        As an organization owner, signing up for MuslimDo opens up new possibilities
        for event success. With MuslimDo, you can confidently lead your organization
        towards achieving its event goals. You have the power to drive your event's
        vision forward, inspire others, and create memorable experiences that leave
        a lasting impression on attendees.
      </p>
      <div><strong>Key Benefits:</strong></div>
      <p>
        1. Seamless Ticketing,booking, and Check-In: MuslimDo simplifies the ticketing, booking, and
        check-in process for organization owners. Easily create and manage ticket
        types, set pricing, and monitor ticket sales. On the day of the event, use
        MuslimDo's efficient check-in system to streamline attendee registration and
        ensure a smooth entry process.
      </p>
      <p>
        2. Data-Driven Insights: Gain valuable
        insights into your organization's event performance through MuslimDo's
        robust analytics. Access data on attendance, engagement, revenue, and more.
        Use these insights to evaluate the success of your events, make informed
        decisions, and continuously improve future event planning strategies.
      </p>
      <p>
        3. Fundraising Projects: Create fundraising projects related to your events.
        This feature allows you to raise funds for charitable causes, community
        initiatives, or event-related expenses. Through MuslimDo, you can engage
        your audience and create a positive impact while organizing successful
        events.
      </p>

      <b-row
        class="mt-3"
        align-h="between"
      >
        <b-col md="3">
          <b-button
            block
            variant="primary"
            :to="{name:'organization-users-signup'}"
          >
            Register as organization
          </b-button>
        </b-col>
        <b-col md="3">
          <b-button
            block
            variant="primary"
            :to="{name:'individual-users-signup'}"
          >
            Register as individual business
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
export default {}
</script>

<style>
</style>
